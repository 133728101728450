// @flow
import React from 'react'
import Layout from '../components/layout'
import Embed from '../components/embed'

const IndexPage = () => (
  <Layout centerContent>
    <Embed />
  </Layout>
)

export default IndexPage
