// @flow
import React from 'react'

function Embed() {
  return (
    <div>
      <iframe
        title="dr0p soundcloud"
        width="100%"
        height="480"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/65787956&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      />
      <style jsx>{`
        div {
          display: flex;
          align-self: stretch;
        }

        iframe {
          border: 25px solid rgba(255, 255, 255, 0.3);
        }
      `}</style>
    </div>
  )
}

export default Embed
